<template>
	<div class="container">
		<top-header></top-header>
		<div class="cboperationinfo">
			<div class="width-1300">
				<data-map :userJson="userJson"></data-map>
				<div class="mineinfo">
					<div class="left">
						<img src="../../../static/images/bz2.jpg" alt="">
						<div class="name">
							<span>Loaction</span>
							<span>Sumbawa</span>
						</div>
						<h4>Project Contacts</h4>
						<div class="address">
							<p>Esquilache Building Calle Esquilache</p>
							<p>371 - piso 10 San lsidro</p>
							<p>Lima</p>
							<p>Peru</p>
						</div>
					</div>
					<div class="right">
						<div class="title">
							<h4 class="title-h4">信息介绍</h4>
						</div>
						<div class="table">
							<div class="tr">
								<div class="td">Stage</div>
								<div class="td">construction</div>
							</div>
							<div class="tr">
								<div class="td">Stage</div>
								<div class="td">construction</div>
							</div>
							<div class="tr">
								<div class="td">Stage</div>
								<div class="td">
									<ul>
										<li>Copper</li>
										<li>Molybdenum</li>
									</ul>
								</div>
							</div>
							<div class="tr">
								<div class="td">Stage</div>
								<div class="td">
									construction
								</div>
							</div>
							<div class="tr">
								<div class="td">Stage</div>
								<div class="td">
									construction
								</div>
							</div>
							<div class="tr">
								<div class="td">Stage</div>
								<div class="td">
									construction
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 信息 -->
			<div class="cbdatabaseinfo">
				<div class="width-1300">
					<div class="info">
						<div class="right">
							<div class="banner">
								<div class="title">
									<span>衬板状态图</span>
								</div>
								<el-carousel height="450px">
									<el-carousel-item v-for="item in 4" :key="item">
										<img src="../../../static/images/45984866-16201266561813858_origin.png" alt="">
									</el-carousel-item>
								</el-carousel>
							</div>
							<div class="sketch">
								<div class="title">
									<span>3D效果图</span>
									<iframe src="./test_3d.html" frameborder="0"></iframe>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<bottom-footer></bottom-footer>
	</div>
</template>

<script>
	import topHeader from '../../components/header.vue'
	import bottomFooter from '../../components/footer.vue'
	import dataMap from '../../components/map/mapEcharts1.vue'
	export default {
		components: {
			topHeader,
			bottomFooter,
			dataMap
		},
		data() {
			return {
				userJson:[{
					amount: 22,
					countryId: 31,
					district: "中国",
					id: 106132,
					latitude: "35.86166",
					longitude: "104.195397",
				}],
				acid:0,
				page:1,
				num:10,
				tableData: [{
					id: 1,
					date: '2016-05-02',
					name: '金属衬板28日',
					smTime: '2020-06-01'
				}, {
					id: 1,
					date: '2016-05-04',
					name: '金属衬板28日',
					smTime: '2020-06-01'
				}, {
					id: 1,
					date: '2016-05-01',
					name: '金属衬板28日',
					smTime: '2020-06-01'
				}]
				
				
			}
		},
		created() {

		},
		beforeDestroy() {

		},
		methods: {
			typeqh(index){
				this.acid = index;
			},
			//设置表头背景色
			headerCellstyle() {
				return 'backgroundColor:#FAFAFA'
			},
			handleCurrentChange(val) {
				//console.log(`当前页: ${val}`);
			},
			handleEdit(index, row) {
				//console.log(index, row);
			},
		}
	}
</script>

<style lang="scss">
	.btn-prev,.btn-next{
		border: 1px solid !important;
		width: 40px !important;
		height: 40px !important;
		border-radius: 50% !important;
	}
	.el-pager{
		vertical-align: middle;
		margin-top: 10px;
	}
	.el-pagination.is-background .el-pager li{
		background-color: #fff;
		font-size: 14px;
	}
	.el-pagination.is-background .el-pager li:not(.disabled).active{
		background-color: #fff;
		color: #409EFF;
		text-decoration: underline;
	}
	.cbdatabaseinfo {
		border-top: 1px solid #888;
		padding: 20px 0;

		.title {
			color: #0058B1;
			font-weight: 800;
		}

		.info {
			margin-top: 30px;

			.left {
				width: 49%;
				display: inline-block;
				vertical-align: top;
				padding-right: 40px;
				box-sizing: border-box;
				@media screen and(min-width:320px) and (max-width:767px) {
					width: 100%;
					margin-bottom: 30px;
					padding-right: 0;
				}
				.text{
					color: #0064C8;
					margin-bottom: 30px;
					text-align: center;
					span{
						cursor: pointer;
						font-weight: 800;
						letter-spacing: 1px;
					}
				}
				.table {
					margin-top: 30px;
					.cell{
						.el-button{
							border-color: #215FB0;
							background-color: rgba($color: #fff, $alpha: 0);
							color: #215FB0;
						}
					}
				}
				
				.page {
					text-align: center;
					margin-top: 30px;
				}
				.search {
					margin: 20px 0px;
					border: 1px solid #888;
					border-radius: 40px;
					@media screen and(min-width:320px) and (max-width:767px) {
						margin: 20px 10px;
					}	
				
					input {
						// border-top: 1px solid #888;
						// border-bottom: 1px solid #888;
						// border-left: 1px solid #888;
						border-top-left-radius: 40px;
						border-bottom-left-radius: 40px;
						padding: 0 15px;
						box-sizing: border-box;
						border-right: 0;
						width: 87.3%;
						height: 40px;
						vertical-align: middle;
						outline: none;
						transition: border-color .3s;
						background-color: rgba($color: #ebebeb, $alpha: .4);
						border: 0;
				
						&:focus {
							border-color: #0058B1;
						}
				
						@media screen and(min-width:320px) and (max-width:767px) {
							width: 79%;
						}
					}
				
					button {
						border: 0px;
						vertical-align: middle;
						cursor: pointer;
						color: #000;
						width: 12.6%;
						height: 40px;
						background-color: rgba($color: #ebebeb, $alpha: .4);
						border-top-right-radius: 40px;
						border-bottom-right-radius: 40px;
				
						@media screen and(min-width:320px) and (max-width:767px) {
							width: 20.8%;
						}
					}
				}
			}

			.right {
				@media screen and(min-width:320px) and (max-width:767px) {
					width: 100%;
					padding-left: 0;
					border-left: 0;
				}
				.title{
					margin-bottom: 20px;
					text-align: center;
				}
				.sketch{
					margin-top: 30px;
					iframe{
						width: 100%;
						margin-top: 20px;
						height: 500px;
					}
				}
			}
		}

		.type {
			margin-top: 20px;
			overflow-x:auto;
			white-space:nowrap;
			padding-bottom: 15px;
			overflow: hidden;
			.item {
				display: inline-block;
				width: 150px;
				padding: 10px 0;
				background-color: #EBEBEB;
				text-align: center;
				font-size: 14px;
				cursor: pointer;
				&:nth-child(1){
					border-top-left-radius: 10px;
					border-bottom-left-radius: 10px;
				}
				&:last-child{
					border-top-right-radius: 10px;
					border-bottom-right-radius: 10px;
				}
				@media screen and(min-width:320px) and (max-width:767px) {
					width: 120px;
				}

				&:hover {
					background: linear-gradient(-61deg, #3995F0, #0075EA, #0064C8);
					color: #fff;
				}
			}

			.active {
				background: linear-gradient(-61deg, #3995F0, #0075EA, #0064C8);
				color: #fff;
			}
		}
	}

	.image {
		margin-bottom: 40px;
		text-align: center;

		iframe {
			margin-top: 20px;
			// border: 0;
			width: 50%;
			height: 400px;

			@media screen and (min-width:320px) and (max-width:767px) {
				width: 100%;
			}
		}
	}

	.title-h4 {
		color: #0D4DBA;
		margin: 0 0;
		font-size: 16px;
	}

	.mineinfo {
		margin: 40px 0;
		.left {
			width: 20%;
			margin-right: 3%;
			display: inline-block;
			font-size: 14px;

			img {
				width: 100%;
			}

			@media screen and(min-width:320px) and(max-width:767px) {
				width: 100%;
				margin-right: 0;
			}

			.name {
				margin: 10px 0;

				span {
					&:last-child {
						margin-left: 50px;
						color: #3292F3;
					}
				}
			}

			h4 {
				margin: 0 0;
			}

			.address {
				color: #3292F3;
				margin-top: 10px;
			}
		}

		.right {
			width: 77%;
			display: inline-block;
			vertical-align: top;

			@media screen and(min-width:320px) and(max-width:767px) {
				width: 100%;
			}

			.title {
				text-align: center;
			}

			.table {
				border-top: 1px solid #dcdcdc;
				margin-top: 20px;

				.tr {
					border-bottom: 1px solid #dcdcdc;

					&:nth-child(2n-1) {
						background-color: #F2F3F3;
					}

					.td {
						display: inline-block;
						vertical-align: middle;
						width: 75%;
						border-left: 1px solid #dcdcdc;
						padding: 10px 40px;
						box-sizing: border-box;

						@media screen and(min-width:320px) and(max-width:767px) {
							padding: 10px;
							font-size: 14px;
						}

						&:nth-child(1) {
							width: 24.9%;
							text-align: right;
							border-left: 0;
							font-weight: 800;
						}
					}
				}

				ul {
					margin: 0 0;
					padding: 0 0;

					@media screen and(min-width:320px) and(max-width:767px) {
						margin-left: 20px;
					}
				}
			}
		}
	}

	.top-nav {
		padding: 20px 0;

		.nav-item {
			font-size: 14px;
			width: 170px;
			display: inline-block;
			padding: 8px 0;
			background-color: #EBEBEB;
			text-align: center;
			cursor: pointer;
			transition: all .4s;
			
			&:hover {
				background-color: #3292F3;
				color: #fff;

				@media screen and(min-width:320px) and (max-width:767px) {
					background-color: #fff;
					color: #000;
				}
			}

			@media screen and(min-width:320px) and (max-width:767px) {
				width: 94%;
				display: block;
				margin: 0 auto;
				padding: 15px 0;
				border-bottom: 1px solid #888;
				background-color: #fff;
			}
		}

		.active {
			background-color: #3292F3;
			color: #fff;

			@media screen and(min-width:320px) and (max-width:767px) {
				border-bottom: 1px solid #3292F3;
			}
		}
	}

	.cboperationinfo {
		margin-top: 85px;
		min-height: 803px;

		@media screen and(min-width:320px) and (max-width:767px) {
			margin-top: 60px;
		}
	}
	.el-carousel__button {
		width: 20px;
		background-color: rgba($color: #fff, $alpha: .5);
	}
	
	.is-active .el-carousel__button {
		background-color: #fff !important;
	}
</style>
